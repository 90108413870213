.temperature-animation {
  animation: 2s ease-in-out tempAnimation infinite;
}

@keyframes tempAnimation {
  0% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(-5px);
  }
}